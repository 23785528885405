import './style.css'
import communityImage from '../assets/images/communityImage.png'
import lightImage from '../assets/images/lightImage.png'
import { useState } from 'react'

const Section3 = ({ bgColor, logoVisible }) => {
  const [menuSelect, setMenuSelect] = useState(1)
  return (
    <>
      {bgColor && logoVisible && (
        <>
          {
            <div className='row'>
              <div
                onClick={() => setMenuSelect(1)}
                className='col-12 col-md-4'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  className={menuSelect === 1 ? 'menuBoxActive' : 'menuBox'}
                >
                  Neler Yapıyoruz ?
                </span>
              </div>
              <div
                onClick={() => setMenuSelect(2)}
                className='col-12 col-md-4'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  className={menuSelect === 2 ? 'menuBoxActive' : 'menuBox'}
                >
                  Neden Biz ?
                </span>
              </div>
              <div
                onClick={() => setMenuSelect(3)}
                className='col-12 col-md-4'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  className={menuSelect === 3 ? 'menuBoxActive' : 'menuBox'}
                >
                  İletişim
                </span>
              </div>
            </div>
          }

          {menuSelect === 1 && (
            <div className='row'>
              <div className='col-12 col-md-6 menuDiv'>
                <div className='menuText'>
                  <span style={{ color: 'red' }}>"</span>
                  <span>
                    {' '}
                    Web tasarımı ve web tabanlı uygulamalar geliştiriyoruz.
                    Online varlığınızı modern ve özelleştirilmiş çözümlerle
                    güçlendiriyoruz.{' '}
                  </span>
                  <span style={{ color: 'red' }}>"</span>
                </div>
              </div>
              <div className='col-12 col-md-6 menuDiv2'>
                <img
                  src={communityImage}
                  alt='communityImage'
                  width='80%'
                  style={{
                    borderRadius: '16px',
                  }}
                />
              </div>
            </div>
          )}

          {menuSelect === 2 && (
            <div className='row'>
              <div
                className='col-12 col-md-6'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5vh',
                }}
              >
                <img
                  style={{ borderRadius: '16px' }}
                  src={lightImage}
                  alt='lightImage'
                  width='70%'
                />
              </div>
              <div className='col-12 col-md-6 menuDiv3'>
                <div className='menuText'>
                  <span>
                    <span style={{ color: 'red' }}>"</span> Teklifimizi değil{' '}
                    <span
                      style={{
                        textDecoration: 'underline',
                        textDecorationColor: 'red',
                        textUnderlineOffset: '7px',
                      }}
                    >
                      fikrimizi
                    </span>{' '}
                    alın, bizim için önemli olan sizin ihtiyaçlarınızı anlamak
                    ve doğru çözümleri sunmak.{' '}
                    <span style={{ color: 'red' }}>"</span>
                  </span>
                </div>
              </div>
            </div>
          )}

          {menuSelect === 3 && (
            <div className='row'>
              <div className='col-md-4'></div>
              <div className='col-12 col-md-4 menuDiv'>
                <div className='section2Div'>
                  <a
                    href='https://www.noktahatasiz.com'
                    target='_blank'
                    style={{
                      visibility: `${logoVisible ? 'visible' : 'hidden'}`,
                    }}
                  >
                    <div className='section2CardDiv'>
                      <div className='section2CardText1'>
                        <span
                          style={{ display: 'block', marginBottom: '-5px' }}
                        >
                          nokta
                        </span>
                        <span
                          style={{ display: 'block', marginBottom: '-6px' }}
                        >
                          hatasiz
                        </span>
                        <span style={{ display: 'block' }}>.com</span>
                      </div>
                      <div className='section2CardTextBorder'></div>
                      <div className='section2CardText2'>
                        <span style={{ display: 'block' }}>Sıfır gibi</span>
                        <span style={{ display: 'block' }}>ikinci el.</span>
                      </div>
                    </div>
                  </a>
                  <div className='section2Footer'>
                    <a
                      className='section2FooterText'
                      style={{
                        visibility: `${bgColor ? 'visible' : 'hidden'}`,
                      }}
                      href='mailto:iletisim@noktateknoloji.com'
                    >
                      iletisim@noktateknoloji.com
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'></div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Section3
