import React, { useEffect, useState } from 'react'
import './App.css'
import Section1 from './pages/section1'
import Section3 from './pages/section3'

const App = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const handleClickSection1 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setButtonColor(true)
    setBgColor(false)
    setTimeout(() => {
      setLogoVisible(false)
    }, 400)
    setTimeout(() => {
      setButtonColor(false)
    }, 500)
  }

  const handleClickSection2 = () => {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
    setButtonColor(true)
    setBgColor(true)
    setLogoVisible(true)
    setTimeout(() => {
      setButtonColor(false)
    }, 750)
  }

  const [buttonColor, setButtonColor] = useState(false)
  const [bgColor, setBgColor] = useState(false)
  const [logoVisible, setLogoVisible] = useState(true)
  return (
    <>
      <div
        className='section1Bg'
        style={{
          height: `${window.innerHeight}px`,
        }}
      >
        <div className='section1Main'>
          <Section1
            handleClickSection2={handleClickSection2}
            buttonColor={buttonColor}
          />
        </div>
        {/* <div className='down-button'></div> */}
      </div>
      <div
        className={bgColor ? 'section2Bg2' : 'section2Bg'}
        style={{ height: `${window.innerHeight}px` }}
      >
        <div className='section3Main'>
          <div className='up-button' onClick={handleClickSection1}>
            <span className='arrow'>
              <svg
                className='svgTransition'
                xmlns='http://www.w3.org/2000/svg'
                width='50'
                height='50'
                viewBox='0 0 24 24'
              >
                <path
                  stroke={buttonColor ? 'black' : 'black'}
                  stroke-width='1.2px'
                  fill='none'
                  d='M19 15l-7-7-7 7'
                />
                <path d='M0 0h24v24H0z' fill='none' />
              </svg>
            </span>
          </div>
          <Section3 bgColor={bgColor} logoVisible={logoVisible} />
        </div>
      </div>
    </>
  )
}

export default App
