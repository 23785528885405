import { useEffect, useState } from 'react'
import PcImage from '../assets/images/pcImage.png'
import './style.css'
const Section1 = ({ handleClickSection2, buttonColor }) => {
  const [imgEffect, setImgEffect] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setImgEffect(!imgEffect)
    }, 2000)
  }, [imgEffect])

  return (
    <>
      <div className='section1Div'>
        <img
          style={{
            opacity: imgEffect ? 1 : 0.3,
            transition: 'opacity 3s ease-in-out',
          }}
          className='imageWidth'
          src={PcImage}
          alt='PcImage'
        />
      </div>
      <div className='section1DivText'>
        <h1 className='textAnimation'>NOKTA TEKNOLOJİ</h1>
      </div>
      <div className='section1Footer'>
        <span className='arrow' onClick={handleClickSection2}>
          <svg
            className='svgTransition'
            xmlns='http://www.w3.org/2000/svg'
            width='60'
            height='60'
            viewBox='0 0 24 24'
          >
            <path
              stroke='black'
              strokeWidth='1.2px'
              fill={buttonColor ? 'black' : 'white'}
              d='M6.41 8.59L12 14.17l5.59-5.58L19 10l-7 7-7-7z'
            />
            <path d='M0 0h24v24H0z' fill='none' />
          </svg>
        </span>
      </div>
    </>
  )
}
export default Section1
